// only import this file once in your project
// includes reset and global styles, default mat-theme and typography

@use '@angular/material' as mat;

@forward 'design-tokens';
@forward 'utilities';
@forward 'layout';

@forward 'base-themes/glxy-light-theme';
@forward 'reset';
@forward 'utility-classes';

@forward 'components/material-overrides';
@forward 'components/snackbar';
@forward 'components/button-toggle-group';

// typography and mat-core
@include mat.core();
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=block');
