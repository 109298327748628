/* You can add global styles to this file, and also import other style files */
@import 'galaxy-base';

// Plus imports for other components in your app.
@import 'uikit';
@import 'uikit_theme';

@include va-uikit-global();
@include va-utilities();
@include va-modal();

html {
  min-height: 100%;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: inherit;
  font-family: Helvetica, Arial, sans-serif;
}
