@use 'design-tokens' as *;

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  @include text-preset-4;
  font-family: $default-font-family;
  background-color: $primary-background-color;
}

a {
  cursor: pointer;
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}

b,
strong {
  font-weight: 600;
}

h1 {
  @include text-preset-1;
}

h2 {
  @include text-preset-2;
}
