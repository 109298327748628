// Configurable breakpoints
// These numbers are based on https://material.io/design/layout/responsive-layout-grid.html#breakpoints
// Changing these should only be done after careful consideration.
$media--phone-minimum: 360px !default; //xs medium handset and up
$media--tablet-minimum: 600px !default; //sm small tablet and up
$media--desktop-minimum: 1024px !default; //md large tablet/small desktop and up
$media--desktop-large-minimum: 1440px !default; //lg medium desktop and up

// Useful utilities for cases the simple forms don't quite support easily.
@mixin media-query($width, $target: 'screen') {
  @media #{$target} and (min-width: #{$width}) {
    @content;
  }
}

// Simple use cases for 80% of needs.
@mixin phone {
  @include media-query($media--phone-minimum) {
    @content;
  }
}

@mixin tablet {
  @include media-query($media--tablet-minimum) {
    @content;
  }
}

@mixin desktop {
  @include media-query($media--desktop-minimum) {
    @content;
  }
}

@mixin desktop-large {
  @include media-query($media--desktop-large-minimum) {
    @content;
  }
}

@mixin desktop-print {
  @include media-query($media--desktop-minimum, 'print') {
    @content;
  }
}
