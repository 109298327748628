@use 'design-tokens' as dt;

@media screen and (max-width: dt.$media--phone-large-minimum) {
  // make input text 16px on smaller screens to avoid iOS zooming on focus
  mat-form-field.mat-form-field,
  mat-form-field.mat-form-field.galaxy-form-field {
    font-size: dt.$font-preset-3-size;
  }
}

// in angular 15, legacy mat-button font-family is being overridden by userstyles
// causing the font to be Arial instead of our fontstack. Resetting the weight to 500 from 400
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-stroked-button,
.mat-mdc-flat-button,
.mdc-button {
  font-family: dt.$default-font-family;
  font-weight: 500;
}

// Icon fix to avoid clipping for resized icons
// https://github.com/angular/components/commit/025a53448342fbda9c2e57819026bafc5ca6f8c9
mat-icon.mat-mdc-icon {
  overflow: visible;
}

// Icon button diameter increased from 40->48 in MDC. Restoring 40px size for compatibility with existing layouts
// Touch target is still 48px
.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}

// basic containers
mat-stepper.mat-stepper,
mat-expansion-panel.mat-expansion-panel,
mat-card.mat-mdc-card,
mat-paginator.mat-mdc-paginator,
.mat-mdc-table,
.mat-mdc-menu-panel,
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
  background: dt.$card-background-color;
}

mat-card-header.mat-mdc-card-header {
  &:not(:last-child) {
    border-bottom: 1px solid dt.$border-color;
  }
  + mat-card-content {
    margin-top: dt.$spacing-3;
  }
  mat-card-title {
    margin-bottom: dt.$spacing-3;

    + mat-card-subtitle {
      margin-top: -(dt.$spacing-3);
      margin-bottom: dt.$spacing-3;
    }
  }
}

mat-card-actions {
  border-top: 1px solid dt.$border-color;
  margin-top: dt.$spacing-3;

  button:not(:last-child) {
    margin-right: dt.$spacing-2;
  }
}

// expansion panels / accordion
mat-expansion-panel.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
  border: 1px solid dt.$border-color;
}
mat-expansion-panel.mat-expansion-panel:not([class*='mat-expanded']) {
  margin-top: -1px; // make it look like a 1px border between accordion panels
}
mat-expansion-panel-header.mat-expansion-panel-header {
  padding: 0 dt.$spacing-3;
}
.mat-expansion-panel-body.mat-expansion-panel-body {
  border-top: 1px solid dt.$border-color;
  padding: dt.$spacing-3;

  > *:first-child:not(.row-gutters) {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
.mat-expansion-panel .mat-expansion-indicator::after {
  // center the expansion triangle
  transform: translateY(-3px) rotate(45deg);
}

// Mat Dialog
.mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin-bottom: dt.$spacing-3;

  + mat-dialog-content.mat-mdc-dialog-content {
    border-top: 1px solid dt.$border-color;
    padding-top: dt.$spacing-3;
  }
}

.mat-mdc-dialog-content + .mat-mdc-dialog-actions {
  border-top: 1px solid dt.$border-color;
  padding: dt.$spacing-3 dt.$spacing-4;
  justify-content: flex-end;
}

mat-dialog-actions .dialog-secondary-actions {
  flex: 1;
}

// Mat Tabs
// re-add the border to the bottom of the tab header
// that was removed in the mdc component update
.mat-mdc-tab-header:after {
  content: '';
  display: block;
  height: 1px;
  background-color: dt.$border-color;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

// mat-tooltip - increase default font size
.mat-mdc-tooltip .mdc-tooltip__surface {
  font-size: dt.$font-preset-5-size;
}

// mat-mdc-menu-panel ships with a max-width of 280px, which seems oddly
// specific. no way to override this at the instance level afaict, because the
// menu panel is rendered in a portal, not in the component itself, so local
// styles can't be applied, hence the global override here. This uses two
// classnames for additioanl specificity because the stock styles appear to be
// added later and thus they win out unless we're more specific here.
.mat-mdc-menu-panel.fit-content {
  max-width: fit-content;
}
