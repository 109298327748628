// Mixins and utility classes that can by further broken into partials
@use 'design-tokens' as dt;

// Quick, consistent media-queries using the named cases below
@mixin media-query($width, $target: 'screen') {
  @media #{$target} and (min-width: #{$width}) {
    @content;
  }
}

@mixin phone {
  @include media-query(dt.$media--phone-minimum) {
    @content;
  }
}

@mixin phone-large {
  @include media-query(dt.$media--phone-large-minimum) {
    @content;
  }
}

@mixin tablet {
  @include media-query(dt.$media--tablet-minimum) {
    @content;
  }
}

@mixin tablet-large {
  @include media-query(dt.$media--tablet-large-minimum) {
    @content;
  }
}

@mixin desktop {
  @include media-query(dt.$media--desktop-minimum) {
    @content;
  }
}

@mixin desktop-medium {
  @include media-query(dt.$media--desktop-medium-minimum) {
    @content;
  }
}

@mixin desktop-large {
  @include media-query(dt.$media--desktop-large-minimum) {
    @content;
  }
}

@mixin desktop-print {
  @include media-query(dt.$media--desktop-minimum, 'print') {
    @content;
  }
}
