@use 'design-tokens' as dt;

// TODO: Determine if this file is used anywhere. If not, delete it.
// Reply from Joel: It is used. This is a set of style overrides for the mat-button-toggle-group component
// that is included in all apps/libs using galaxy base styles

.mat-button-toggle-group[activeColor*='primary'].mat-button-toggle-group-appearance-standard {
  .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: dt.$primary-accent-color;
    color: dt.$white;
  }
}

// Default Size
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard,
.glxy-dark-theme .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 34px; //with borders it has a height of 36px
    padding: 0 15px;
  }
  .mat-icon {
    display: inline-block;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    width: 20px;
    vertical-align: middle;
    position: relative;
    top: -1px;

    svg {
      position: relative;
      top: -1px;
    }
  }
}

// Large
.mat-button-toggle-group[size*='large'].mat-button-toggle-group-appearance-standard,
.glxy-form-field.size-large .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 46px; //with borders it has a height of 48px
    font-size: 15px;
    padding: 0 16px;
  }
  .mat-icon {
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    margin: 0 -2px;
  }
}

// Small
.mat-button-toggle-group[size*='small'].mat-button-toggle-group-appearance-standard,
.glxy-form-field.size-small .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 30px; //with borders it has a height of 32px
    padding: 0 10px;
  }
}

// optionally hide outer border
.mat-button-toggle-group[hideBorder*='true'].mat-button-toggle-group-appearance-standard,
.glxy-form-field .hide-decoration .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border-color: transparent;
}

// Slightly bold text when selected
.mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  font-weight: 500;
}

// remove background color so it matches regular mat-stroked-button
.mat-button-toggle-group[hideBackgroundColor*='true']
  .mat-button-toggle.mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked),
.glxy-dark-theme
  .mat-button-toggle-group[hideBackgroundColor*='true']
  .mat-button-toggle.mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked) {
  background-color: unset;
}

// darken the selected state background in dark mode
.glxy-dark-theme .mat-button-toggle.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background-color: rgba(0, 0, 0, 0.4);
}
