@use '@angular/material' as mat;
@use 'design-tokens' as *;
@forward 'design-tokens';

$custom-typography: mat.m2-define-typography-config(
  $font-family: $default-font-family,
  $headline-1: mat.m2-define-typography-level($font-preset-1-size, 1.2, 400),
  $headline-2: mat.m2-define-typography-level($font-preset-1-size, 1.2, 400),
  $headline-5: mat.m2-define-typography-level($font-preset-2-size, 1.2, 500),
  $headline-6: mat.m2-define-typography-level($font-preset-3-size, 1.2, 400),
  $subtitle-1: mat.m2-define-typography-level($font-preset-4-size, 1.2, 400),
  $subtitle-2: mat.m2-define-typography-level($font-preset-4-size, 1.2, 400),
  $body-1: mat.m2-define-typography-level($font-preset-4-size, 1.4, 400),
  $body-2: mat.m2-define-typography-level($font-preset-4-size, 1.4, 400),
  $caption: mat.m2-define-typography-level($font-preset-5-size, 1.2, 400),
  $button: mat.m2-define-typography-level($font-preset-4-size, 1.125, 400),
  $overline: null,
);

$app-primary: mat.m2-define-palette(mat.$m2-blue-palette, 700);
$app-accent: mat.m2-define-palette(mat.$m2-blue-palette, 700);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette, 700);
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $custom-typography,
    density: -2,
  )
);

$zero-density-theme: mat.m2-define-light-theme(
  (
    density: 0,
  )
);

@include mat.all-component-themes($app-theme);
@include mat.button-density($zero-density-theme);

// Get mat-colors to use in theme
$mat-fg: mat.get-theme-color($app-theme, foreground);
$mat-bg: mat.get-theme-color($app-theme, background);

:root {
  // set up default theme colors
  --primary-text-color: #{$glxy-grey-900};
  --secondary-text-color: #{$glxy-grey-700};
  --tertiary-text-color: #{$glxy-grey-500};
  --border-color: #{$glxy-grey-300};
  --weak-border-color: rgba(0, 0, 0, 0.04);
  --primary-color: #{$glxy-blue-700};
  --icon-color: rgba(0, 0, 0, 0.54);
  --primary-background-color: #{$glxy-grey-50};
  --primary-background-selected-color: #{rgba($glxy-blue-700, 0.16)};
  --secondary-background-color: #{$glxy-grey-100};
  --card-background-color: #{$white};
  --card-overlay-color: rgba(255, 255, 255, 0.5);
  --row-hover-bg-color: #f5f9fc;

  --link-color: #{$glxy-blue-700};
  --link-hover-color: #{$glxy-blue-800};

  --primary-button-bg-hover-color: #{$glxy-blue-800};
  --warn-button-bg-hover-color: #{$glxy-red-800};
  --secondary-button-border-strong-color: rgba(0, 0, 0, 0.2);

  --info-background-color: #{$glxy-blue-50};
  --info-border-color: #{$glxy-blue-200};
  --info-icon-color: #{$glxy-blue-800};

  --warn-background-color: #{$glxy-yellow-50};
  --warn-border-color: #{$glxy-yellow-500};
  --warn-icon-color: #{$glxy-yellow-800};

  --success-background-color: #{$glxy-green-50};
  --success-border-color: #{$glxy-green-300};
  --success-icon-color: #{$glxy-green-800};
  --success-text-color: #{$glxy-green-800};

  --error-background-color: #{$glxy-red-50};
  --error-border-color: #{$glxy-red-600};
  --error-icon-color: #{$glxy-red-800};
  --error-text-color: #{$glxy-red-800};

  --contrast-background-color: #{$glxy-grey-900};
  --contrast-border-color: #{$glxy-grey-700};
  --contrast-icon-color: #{$glxy-grey-200};
  --contrast-text-color: #{$white};

  --field-border-color: #{rgba($glxy-grey-400, 0.6)};
  --field-border-disabled-color: #{$glxy-grey-300};
  --field-background-color: #{$white};
  --field-background-disabled-color: #{$glxy-grey-50};
  --select-arrow-color: rgba(0, 0, 0, 0.54);

  --nav-list-item-active-color: rgba(0, 0, 0, 0.05);
  --nav-list-item-hover-color: rgba(0, 0, 0, 0.05);
  --sublist-background-color: #{$glxy-grey-100};
  --nav-item-text-color: #{$glxy-grey-900};
}
