@use '@angular/material' as mat;
@use 'design-tokens' as dt;

// Only use this file for variables and mixins

/////////////////////
// Style Variables //
/////////////////////
@use 'media' as *;
@forward 'shimmer';
@forward 'flex_grid';
@forward 'styles/typography'; //transition some of these to the official Material from @angular/material/theming.scss
@forward 'styles/spacing';
@forward 'styles/alerts';

$page-background: dt.$lighter-gray !default;
$layout-width: 1200px !default;
$nav-width: 276px !default;

//////////////////////////////////////
// Overall Layout and Style Choices //
//////////////////////////////////////

@mixin va-uikit-global {
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: $page-background;
    font-family: $default-font-stack;
  }

  mat-toolbar[app-toolbar] {
    @include mat.elevation(2);
    padding: 0 $gutter-width-dense;
    background-color: $page-background;
    z-index: 2;

    @include desktop {
      padding: 0 $gutter-width;
    }
  }

  .content {
    max-width: $layout-width;
    padding: $gutter-width-dense;
    margin: 0 auto;

    @include desktop {
      padding: $gutter-width;
    }
  }

  a {
    color: dt.$blue;
    text-decoration: none;
    cursor: pointer;

    &:visited {
      color: dt.$blue;
    }

    &:hover {
      color: dt.$blue;
    }
  }
}

@mixin va-modal {
  //modal overrides
  .cdk-overlay-pane {
    pointer-events: none !important; //when setting the width, need to keep background area clickable to close modal

    > * {
      pointer-events: auto; //pointer events are restored here
    }
  }

  [mat-dialog-title].mat-dialog-title {
    margin: 16px 0;
    line-height: 1.2;
  }

  [mat-dialog-title] + mat-dialog-content {
    border-top: 1px solid dt.$border-color;
  }

  mat-dialog-container.mat-dialog-container {
    margin: auto;
    padding-top: 0;
  }

  mat-dialog-actions.mat-dialog-actions {
    justify-content: flex-end;
    align-items: center;
    padding: 12px 24px;
    margin-left: -24px;
    margin-right: -24px;
    background-color: dt.$row-hover-bg-color;
  }
  // modal additions
  .dialog-secondary-actions {
    flex: 1;
  }
}

/////////////////////
// Utility Classes //
/////////////////////

@mixin va-utilities {
  va-nav.responsive-nav {
    // Overrides to get desired nav look/behaviour:
    // - always open at tablet(ish) and above, next to content
    // - hidden on mobile, covers content when open
    // Angular Material could ship a change to break this :)
    .mat-toolbar[app-toolbar] {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    main {
      padding-top: 64px;
    }

    @include desktop {
      .mat-sidenav-container .mat-sidenav,
      .mat-sidenav-container .mat-sidenav.mat-drawer-over {
        transform: translate3d(0, 0, 0);
        visibility: visible !important;
      }

      .mat-sidenav + div + .mat-sidenav-content {
        padding-left: $nav-width;
      }

      .mat-toolbar[app-toolbar] {
        left: $nav-width;
        width: auto;
      }
    }
  }

  va-nav.responsive-nav {
    .va-nav-hidden {
      @include desktop {
        .mat-toolbar[app-toolbar] {
          left: 0;
        }
      }
    }
  }

  va-nav {
    .va-nav-hidden {
      .nav-toggle {
        display: none;
      }
      mat-sidenav {
        display: none;
      }
    }
  }

  .fill-space {
    flex-grow: 1;
    min-width: $gutter-width-dense;
  }

  .hide-on-md-up {
    @include desktop {
      display: none !important;
    }
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
