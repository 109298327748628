snack-bar-container {
  &.snackbar-error {
    simple-snack-bar:before {
      content: 'ERROR: ';
      color: #ff0000;
      font-weight: bold;
      padding-right: 5px;
    }
    button {
      &.mat-simple-snackbar-action {
        margin-left: 16px;
      }
    }
  }

  &.snackbar-success {
    simple-snack-bar:before {
      content: 'SUCCESS: ';
      color: #00ff00;
      font-weight: bold;
      padding-right: 5px;
    }
    button {
      &.mat-simple-snackbar-action {
        margin-left: 16px;
      }
    }
  }
}
