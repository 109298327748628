/* va-nav themes
* How to theme. Based on a dark theme by default, so it's backwards for light themes.
* mat.m2-define-palette($mat-colour, default, lighter, darker)
* Sidebar background: primary default
* Submenu background: primary lighter
* Active nav-item background: accent default
* Nav-item text: theme foreground secondary-text
* Active Nav-item text: theme foreground text
*/
@use '@angular/material' as mat;

$nav-dark-primary: mat.m2-define-palette(mat.$m2-grey-palette, 900, 800);
$nav-dark-accent: mat.m2-define-palette(mat.$m2-grey-palette, 700);
$nav-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $nav-dark-primary,
      accent: $nav-dark-accent,
    ),
  )
);

$nav-light-primary: mat.m2-define-palette(mat.$m2-grey-palette, 50, 200);
$nav-light-accent: mat.m2-define-palette(mat.$m2-blue-palette, 100);
$nav-light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $nav-light-primary,
      accent: $nav-light-accent,
    ),
  )
);

$nav-green-primary: mat.m2-define-palette(mat.$m2-green-palette, 800, 700);
$nav-green-accent: mat.m2-define-palette(mat.$m2-green-palette, 600);
$nav-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $nav-green-primary,
      accent: $nav-green-accent,
    ),
  )
);
