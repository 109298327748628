@use 'design-tokens' as dt;

.cdk-overlay-pane .mat-mdc-snack-bar-container {
  padding-bottom: dt.$spacing-2;
}

simple-snack-bar.mat-mdc-simple-snack-bar {
  [matsnackbarlabel] {
    color: dt.$contrast-text-color;
    word-break: break-word;
    flex-grow: 100;
    display: flex;
    align-items: center;
  }

  @media (max-width: dt.$media--tablet-minimum) {
    flex-wrap: wrap;
  }

  [matsnackbaractions] {
    margin: dt.$spacing-2 dt.$spacing-2 dt.$spacing-2 dt.$spacing-3;
    display: flex;
    align-items: center;

    button[matsnackbaraction] {
      color: dt.$contrast-text-color !important;
      border-radius: dt.$default-border-radius;
      border: 1px solid dt.$contrast-border-color !important;
      width: 100%;
    }

    @media (max-width: dt.$media--tablet-minimum) {
      flex-grow: 1;
      text-align: center;
      margin-top: 0;
    }
  }
}

mat-snack-bar-container.error-snackbar [matsnackbarlabel]:before,
mat-snack-bar-container.success-snackbar [matsnackbarlabel]:before {
  display: block;
  content: '';
  flex-grow: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: dt.$spacing-2;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

mat-snack-bar-container.error-snackbar [matsnackbarlabel]:before {
  background-image: url('../../assets/img/icon-error.svg');
}

mat-snack-bar-container.success-snackbar [matsnackbarlabel]:before {
  background-image: url('../../assets/img/icon-success.svg');
}
